import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Footer = () => {
  return (
    <Wrapper>
      <a href="https://ema.saonas.com">Creado por: Enmanuel Martínez</a>
    </Wrapper>
  );
}
 
export default Footer;